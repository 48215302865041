// extracted by mini-css-extract-plugin
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var blue = "#67b9e8";
export var blueishGrey = "#adc6d6";
export var card = "Video-module--card--KzFrj";
export var categoryBar = "Video-module--categoryBar--i+30t";
export var categoryIcon = "Video-module--categoryIcon--mGPox";
export var categoryTitle = "Video-module--categoryTitle--MoQDO";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "Video-module--date--phaFY";
export var error = "#f66a83";
export var green = "#3dae2b";
export var grey = "#828382";
export var icon = "Video-module--icon--1IQ6D";
export var iframe = "Video-module--iframe--qobq+";
export var image = "Video-module--image--8UTp9";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var orange = "#fc9948";
export var paleBlue = "#64a1bb";
export var success = "#1ebc7b";
export var thumbnailContainer = "Video-module--thumbnailContainer--l6DOf";
export var title = "Video-module--title--kgzn-";
export var titleLight = "Video-module--titleLight--V6RKy";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";