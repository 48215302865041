// extracted by mini-css-extract-plugin
export var accordionClosed = "videos-module--accordionClosed--zel--";
export var accordionIcon = "videos-module--accordionIcon--YkrEh";
export var accordionOpen = "videos-module--accordionOpen--7MfOx";
export var allCheckboxContainer = "videos-module--allCheckboxContainer--4HeHg";
export var almostBlack = "#333";
export var almostWhite = "#f0f0f0";
export var animatebottom = "videos-module--animatebottom--Zpy6m";
export var blue = "#67b9e8";
export var blueBorder = "videos-module--blueBorder--iqNUQ";
export var blueishGrey = "#adc6d6";
export var border = "videos-module--border--74CTt";
export var category = "videos-module--category--Prm-g";
export var checkboxContainer = "videos-module--checkboxContainer--ePFD5";
export var checkboxLabel = "videos-module--checkboxLabel--2YPEF";
export var container = "videos-module--container--yY94Y";
export var coolGrey = "#e1e2e0";
export var darkBlue = "#003e52";
export var darkGreen = "#3b693d";
export var darkGrey = "#4b4d4b";
export var date = "videos-module--date--IKOnd";
export var description = "videos-module--description--YWgzE";
export var downloadIconLink = "videos-module--downloadIconLink--x+sWM";
export var error = "#f66a83";
export var fakeCheckbox = "videos-module--fakeCheckbox--sZDrK";
export var filterContainer = "videos-module--filterContainer--GkxRJ";
export var filterIcon = "videos-module--filterIcon--Topr5";
export var filterLabel = "videos-module--filterLabel--FjIkW";
export var filterLabelContainer = "videos-module--filterLabelContainer--1C8Mt";
export var filterName = "videos-module--filterName--rCPp6";
export var filtersBar = "videos-module--filtersBar--Pq5FP";
export var filtersContainer = "videos-module--filtersContainer--G8iwY";
export var green = "#3dae2b";
export var grey = "#828382";
export var iconLeft = "videos-module--iconLeft--2FsjJ";
export var iconRight = "videos-module--iconRight--QB0LI";
export var infoIcon = "videos-module--infoIcon--ri4GV";
export var label = "videos-module--label--wK1A7";
export var lightGreen = "#93d500";
export var lightGrey = "#aba9a9";
export var link = "videos-module--link--sdLjn";
export var moduleRoot = "videos-module--moduleRoot--N3Pl8";
export var name = "videos-module--name--XYhhL";
export var noVideosResultsText = "videos-module--noVideosResultsText--cBv7R";
export var orange = "#fc9948";
export var overlay = "videos-module--overlay--oPSem";
export var paginationContainer = "videos-module--paginationContainer--BFi3S";
export var paleBlue = "#64a1bb";
export var path = "videos-module--path--C0GAs";
export var pathContainer = "videos-module--pathContainer--SHgFu";
export var pathTitle = "videos-module--pathTitle--RUO+8";
export var sectionTitleContainer = "videos-module--sectionTitleContainer--vDj3c";
export var separator = "videos-module--separator--qkSQB";
export var shareButtonBottomContainer = "videos-module--shareButtonBottomContainer--+wngg";
export var shareCardWrapper = "videos-module--shareCardWrapper--lUs0m";
export var shareLink = "videos-module--shareLink--OkEMu";
export var success = "#1ebc7b";
export var video = "videos-module--video--j27Yv";
export var videoCard = "videos-module--videoCard--+pPkP";
export var videoContainer = "videos-module--videoContainer--UWAHt";
export var videoLabels = "videos-module--videoLabels--Ri7T0";
export var warning = "#ffeda0";
export var white = "#fff";
export var yellow = "#fbc900";