import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Portal } from '@material-ui/core';
import getYouTubeID from 'get-youtube-id';
import * as Sentry from '@sentry/react';

import Layout from '../../components/Layout';
import GenericLink from '../../components/GenericLink';
import Video from '../../components/Video';
import ShareCard from '../../components/ShareCard';
import {
  getFirstModuleBreadcrumbValuesByTypename,
  removeItem,
  slugify,
  sortBy,
} from '../../utils/utils';
import { PageBreadcrumbValuesByTypename } from '../../graphql-fragments/pageBreadCrumbValues';

import { FaChevronDown, FaChevronRight, FaFilter, FaShare } from 'react-icons/fa';
import * as styles from './videos.module.scss';
import { withI18n } from '../../utils/hocs';
import { useLocalization, usePagination, useUpdateUrlFromFilters } from '../../utils/hooks';
import PageSEO from '../../components/PageSEO';
import LocalizedLink from '../../components/LocalizedLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LocalizedQueryData {
  allSanityPage: {
    nodes: Array<PageBreadcrumbValuesByTypename>;
  };
  allSanityAssetsVideo: {
    nodes: Array<{
      name: string;
      category: {
        title: string;
      };
      url: string;
      date: string;
      description?: string;
      _id: string;
    }>;
  };
}

const VIDEOS_PER_PAGE = 4;

const VideosPage = (): React.ReactElement => {
  const [openedShareVideoId, setOpenedShareVideoId] = useState<string | null>(null);

  const data = useStaticQuery(
    graphql`
      {
        allSanityPage {
          nodes {
            ...PageBreadcrumbValuesByTypename
          }
        }
        allSanityAssetsVideo {
          nodes {
            name {
              ...LocaleStringFragment
            }
            category {
              title {
                ...LocaleStringFragment
              }
            }
            url {
              ...LocaleStringFragment
            }
            date
            description {
              ...LocaleTextFragment
            }
            _id
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  const { nodes: assetsVideos } = localizedData.allSanityAssetsVideo;
  const { nodes: pages } = localizedData.allSanityPage;

  const sortedAssetsVideos = sortBy(assetsVideos, [[video => video.date, 'desc']]);

  const firstModuleData = getFirstModuleBreadcrumbValuesByTypename(pages, 'SanityAssetsModule');

  const categories: Array<string> = [];
  for (const videoCategory of sortedAssetsVideos) {
    if (!categories.some(title => title === videoCategory.category.title)) {
      categories.push(videoCategory.category.title);
    }
  }

  const [selectedCategories, setSelectedCategories] = useState(categories);

  const filteredVideos = sortedAssetsVideos.filter(videos =>
    selectedCategories.includes(videos.category.title),
  );

  const {
    currentPage,
    currentPageItems: currentPageVideos,
    getPageUrlPart,
    resetPagination,
    renderPagination,
  } = usePagination(filteredVideos, VIDEOS_PER_PAGE);

  // Update url on filters state change
  useUpdateUrlFromFilters(() => [getPageUrlPart()], [currentPage]);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Layout transparentHeader={false}>
      <PageSEO defaultTitle={t('videos_page.title', 'Videos')}></PageSEO>
      <div className={styles.moduleRoot}>
        <div className={styles.container}>
          <div className={styles.pathContainer}>
            <div className={styles.path}>
              {firstModuleData && (
                <LocalizedLink to={firstModuleData.url} className={'titleParagraph ' + styles.link}>
                  {/* <FontAwesomeIcon icon="chevron-left" className={styles.iconLeft} /> */}
                  {firstModuleData.name}
                </LocalizedLink>
              )}
              <FaChevronRight className={styles.iconLeft}></FaChevronRight>
            </div>
            <div>
              <span className={'titleParagraph ' + styles.pathTitle}>
                {t('videos_page.title', 'Videos')}
              </span>
            </div>
          </div>

          <div className={styles.sectionTitleContainer}>
            <div className={styles.border}></div>
            <h2>{t('videos_page.title', 'Videos')}</h2>
            <div className={styles.border}></div>
          </div>

          <div className={styles.filtersBar}>
            <div
              className={
                styles.filterLabelContainer +
                ' ' +
                (isAccordionOpen ? styles.accordionOpen : styles.accordionClosed)
              }
            >
              <div>
                <FaFilter className={styles.filterIcon}></FaFilter>
                <span className={styles.filterLabel}>
                  {t('videos_page.filters.main_label', 'Filters')}
                </span>
              </div>
              <FaChevronDown
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                className={styles.accordionIcon}
              ></FaChevronDown>
            </div>

            <div className={styles.filtersContainer}>
              <div className={styles.blueBorder}></div>
              <div className={styles.checkboxContainer + ' ' + styles.allCheckboxContainer}>
                <label className={styles.checkboxLabel} htmlFor={'allSelected'}>
                  {/* All */}
                  <input
                    type="checkbox"
                    id={'allSelected'}
                    name={'allSelected'}
                    className={styles.checkbox}
                    checked={selectedCategories.length === categories.length}
                    onChange={e => {
                      resetPagination();
                      if (e.target.checked) {
                        setSelectedCategories(categories);
                      } else {
                        setSelectedCategories([]);
                      }
                    }}
                  ></input>
                  <span className={styles.fakeCheckbox}></span>
                  <span className={styles.label}>
                    {t('videos_page.filters.show_all_label', 'All')}
                  </span>
                </label>
              </div>

              <span className={styles.separator}></span>

              <div className={styles.filterContainer}>
                <span className={styles.filterName}>
                  {t('videos_page.filters.category_label', 'Category')}
                </span>
                <div className={styles.category}>
                  {categories.map((category, index) => {
                    return (
                      <div key={index} className={styles.checkboxContainer}>
                        <label
                          htmlFor={'category-' + slugify(category[index])}
                          className={styles.checkboxLabel}
                        >
                          <input
                            type="checkbox"
                            id={'category-' + slugify(category[index])}
                            name={'category.title'}
                            className={styles.checkbox}
                            checked={!!selectedCategories.find(cat => cat === category)}
                            onChange={e => {
                              resetPagination();
                              setSelectedCategories(
                                e.target.checked
                                  ? [...selectedCategories, category]
                                  : removeItem(selectedCategories, category),
                              );
                            }}
                          ></input>
                          <span className={styles.fakeCheckbox}></span>
                          <span className={styles.label}> {category}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.videoContainer}>
            {currentPageVideos.length === 0 ? (
              <div className={styles.noVideosResultsText}>
                <FontAwesomeIcon icon="info-circle" className={styles.infoIcon} />
                {t('videos_page.no_videos_text', 'No videos found for selected categories.')}
              </div>
            ) : (
              currentPageVideos.map((video, index) => {
                const id = getYouTubeID(video.url);
                if (!id) {
                  throw new Error('Could not extract youtube id from url: ' + video.url);
                }
                const embedUrl = `https://www.youtube.com/embed/${id}`;

                let formatedDate;
                if (video.date) {
                  const date = new Date(video.date);

                  const month = [
                    t('months.short_name.january', 'jan'),
                    t('months.short_name.february', 'feb'),
                    t('months.short_name.march', 'mar'),
                    t('months.short_name.april', 'apr'),
                    t('months.short_name.may', 'may'),
                    t('months.short_name.june', 'jun'),
                    t('months.short_name.july', 'jul'),
                    t('months.short_name.august', 'aug'),
                    t('months.short_name.september', 'sep'),
                    t('months.short_name.october', 'oct'),
                    t('months.short_name.november', 'nov'),
                    t('months.short_name.december', 'dec'),
                  ][date.getMonth()].toUpperCase();
                  const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
                  formatedDate = day + ' ' + month + ' ' + date.getFullYear();
                }

                function openShareVideo() {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: video.name,
                        url: video.url,
                      })
                      .catch(error => {
                        Sentry.captureException(error);
                        console.error(error);
                      });
                  } else {
                    setOpenedShareVideoId(video._id);
                  }
                }

                return (
                  <div className={styles.videoCard} key={index}>
                    <Video
                      className={styles.video}
                      url={embedUrl}
                      category={video.category.title}
                    ></Video>
                    <div className={styles.videoLabels}>
                      <h4 className={styles.name}>{video.name}</h4>
                      {!!video.date && <span className={styles.date}>{formatedDate}</span>}
                      {!!video.description && (
                        <span className={styles.description}>{video.description}</span>
                      )}
                      <GenericLink
                        styleOnly
                        className={styles.shareLink + ' ' + styles.link}
                        onClick={openShareVideo}
                      >
                        {t('videos_page.share_link', 'Share')}

                        <FaShare className={styles.iconRight}></FaShare>
                      </GenericLink>
                    </div>

                    {openedShareVideoId === video._id && (
                      <Portal>
                        <div className={styles.overlay} onClick={() => setOpenedShareVideoId(null)}>
                          <div
                            className={styles.shareCardWrapper}
                            onClick={e => e.stopPropagation()}
                          >
                            <ShareCard url={video.url}></ShareCard>
                          </div>
                        </div>
                      </Portal>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className={styles.paginationContainer}>{renderPagination()}</div>
      </div>
    </Layout>
  );
};

export default withI18n(VideosPage);
